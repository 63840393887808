:root {
    --primary-dark-grey: #666;
    --primary-green: #6bd425;
    --primary-black: #000;
  }
  
  /* ***************** footer **************************** */
  .footer {
    background: var(--primary-black);
    color: var(--primary-dark-grey);
    padding: 3em;
  }
  
  .footer a {
    text-decoration: none;
    color: var(--primary-dark-grey);
  }
  
  .footer a:hover {
    text-decoration: none;
    color: var(--primary-green);
    cursor: pointer;
  }
  
  .footer p {
    margin-bottom: 0;
  }