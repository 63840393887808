@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
:root {
  --primary-green: #6bd425;
  --primary-blue: #247ba0;
  --primary-black: #000;
  --primary-dark: rgba(255, 255, 255, 0);
}


body {
  font-family: 'Roboto' sans-serif !important;
}

/* ***************** Media **************************** */
@media (max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }

  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }

  .timeline-block-right {
    float: none;
    width: 100%;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
}

/* ***************** Overflow box **************************** */
.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid var(--primary-blue);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: var(--primary-green);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

/* ***************** Overflow box **************************** */
.portfolio-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: var(--primary-green);
}

.hyper-link:hover {
  color: var(--primary-blue);
}

/* ***************** Media **************************** */
@media (max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
}

@media (max-height: 570px) {
  .popupbox-wrapper {
    height: 100%;
  }

  .portfolio-image-popupbox {
    width: 50%;
  }
}

@media (max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }

  .portfolio-image-popupbox {
    width: 30%;
  }
}
:root {
    --primary-green: #6bd425;
    --primary-blue: #247ba0;
    --primary-black: #000;
    --primary-dark: rgba(255, 255, 255, 0);
  }
  nav {
    z-index: 2;
    position: fixed;  
    top: 0px;
}

  .logo {
    width: 13.50rem;
    margin-right: 7.50rem;
    margin-left: -19.50rem;

  
  }
  
  /* ------------ Nav Links -----------*/
  nav a.nav-link {
    letter-spacing:  0.075rem;
    cursor: pointer;
       
  }
  
  nav ul li {
    text-transform: uppercase;
    
  }
  
  .navbar-nav > li > a {
    color: var(--primary-green) !important;
    margin-right: 0.75rem;
    margin-left:  0.75rem;
    border-top: 0.1875 solid var(--primary-dark);
    font-weight: bolder;
    font-family: 'Bebas Neue'  cursive !important;
    font-size:  160%;

  }
  
  

  
  
  
  .navbar-brand {
    max-width: 17.25rem;
    padding: 0 !important;
    height: 6rem !important;
  }
  
  @media (max-width: 768px) {
    .navbar-brand {
      width: 7rem;
    }
  }
  
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    background: var(--primary-green);
    border-bottom: 0.3125rem solid var(--primary-dark);  }
  
    .navbar-nav > li > a:hover {
      border-top: 0.1875rem solid var(--primary-green);
    }

:root {
    --primary-white: #e2e2e2;
    --primary-green: #6bd425;
    --primary-blue: #247ba0;
    --primary-hover-blue: #0a2463;
  }
  
  /* ***************** Header **************************** */
  
  .header-wrapper {
    
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)),
      url(/static/media/code.9e44db43.jpeg) no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
  }
  
  .main-info {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1;
  }
  
  .main-info h1 {
    color: var(--primary-green);
    text-transform: uppercase;
    font-weight: bolder;
   
  }
  
  /* --- Typed TEXT ---  */
  .typed-text {
    font-size: 2rem;
    color: var(--primary-green);
    font-weight: bold;
    font-family: Bebas Neue;
  }
  
  @media (max-width: 768px) {
    .main-info h1 {
      font-size: 1.7rem !important;
    }
    .typed-text {
      font-size: 1.3rem !important;
    }
  }
  
  /* --- HEADER MAIN OFFER BUTTON ---  */
  .btn-main-offer {
    border: 1px solid var(--primary-blue);
    text-transform: uppercase;
    border-radius: 0;
    padding: 0.625rem 0.75rem 0.75rem 0.75rem;
    color: var(--primary-white);
    margin: 2rem;
  }
  
  .btn-main-offer:hover {
    text-decoration: none;
    background-color: var(--primary-hover-blue);
    color: var(--primary-white);
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }
  
  /* --- PARTICLES JS ---  */
  .tsparticles-canvas-el {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1;
  }
  
  @media (max-width: 768px) {
    .particles-canvas {
      display: none;
    }
  }
:root {
    --primary-green: #6bd425;
  }
  
  /* ***************** About Me **************************** */
  .photo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-img {
    width: 11rem;
    height: auto;
    border-radius: 50%;
    padding: 0.3rem;
    border: 1px solid var(--primary-green);
  }
  
  .about-heading {
    color: var(--primary-green);
    text-transform: uppercase;
  }
  
  @media (max-width: 468px) {
    .profile-img {
      width: 10rem;
      height: 10rem;
    }
  }
:root {
  --primary-white: #e2e2e2;
  --primary-light-grey: #f4f4f4;
  --primary-green: #6bd425;
  --primary-dark: rgb(52, 58, 64);
}

/* ***************** Services **************************** */
.skills {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.skills h1 {
  color: var(--primary-green);
  text-transform: uppercase;
}

.skills .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-green);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.skills .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.skills .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-green);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
  text-align: center;
}

.skills .box:hover {
  background: var(--primary-green);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.skills .box:hover .icon {
  color: var(--primary-dark);
}

.skills .box:hover .circle {
  background: var(--primary-white);
}

.skills .box:hover p {
  color: var(--primary-white);
}
:root {
    --primary-grey: #605f5e;
    --primary-dark-grey: #666;
    --primary-green: #6bd425;
    --primary-dark: rgb(52, 58, 64);
    --primary-blue: #247ba0;
  }
  
  /* ***************** Experience **************************** */
  .experience {
    padding: 0.7rem;
  }
  
  .experience-wrapper {
    position: relative;
    padding: 3.125rem 0;
    overflow: hidden;
  }
  
  .experience h1 {
    text-transform: uppercase;
    color: var(--primary-green);
  }
  
  .experience-wrapper::before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    margin-left: -0.0625rem;
    width: 0.125rem;
    height: 100%;
    background: var(--primary-grey);
    z-index: 1;
  }
  
  .timeline-block {
    width: calc(50% + 0.5rem);
    display: flex;
    justify-content: space-between;
    clear: both;
  }
  
  .timeline-block-right {
    float: right;
  }
  
  .timeline-block-left {
    float: left;
    direction: rtl;
  }
  
  .marker {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 0.3125rem solid var(--primary-dark);
    background: var(--primary-green);
    margin-top: 0.625rem;
    z-index: 3;
  }
  
  .timeline-content {
    width: 95%;
    padding: 0 0.9375rem;
    color: var(--primary-dark-grey);
    direction: ltr;
  }
  
  .timeline-content h3 {
    margin-top: 0.3125rem;
    color: var(--primary-blue);
    font-weight: 900;
  }
:root {
    --primary-light-grey: #f4f4f4;
    --primary-green: #6bd425;
  }
  .p72 {
    max-width: 40%;
    }
    .Nucamp {
      max-width: 40%;
    }
    .tarjeta {
      max-width:  30%;
    }
  .contacts {

    max-width: 40%;
  }
  .portfolio {
    max-width: 30%;
  }
  /* ***************** Portfolio **************************** */
  .portfolio-wrapper {
    background: var(--primary-light-grey);
    padding: 5rem ;
  }
  
  .portfolio-wrapper h1 {
    color: var(--primary-green);
  }
  
  .portfolio-image {
    width: 18rem;
    border: 1px solid var(--primary-green);
    padding: 0 0.5rem;
  }
  
  .portfolio-image-box {
    position: relative;
    margin: 1rem;
    max-width: 40%;
  }
  
  .overflow {
    width: 18rem;
    height: 11rem;
      }
:root {
    --primary-white: #e2e2e2;
    --primary-light-grey: #f4f4f4;
    --primary-dark-grey: #666;
    --primary-green: #6bd425;
    --primary-dark: rgb(52, 58, 64);
    --primary-hover-blue: #0a2463;
    --primary-black: #000;
    --primary-contact-green: green;
  }
  
  /* ***************** contact **************************** */
  .contact {
    background: var(--primary-dark);
    padding: 3rem 0;
    max-width: 100%;
  }
  
  .contact h1 {
    color: var(--primary-green);
    padding: 1.3rem 0;
    text-transform: uppercase;
  }
  
  .contact p {
    color: var(--primary-light-grey);
    font-size: 1.2rem;
  }
  
  /* --- inputs ---  */
  .contact input,
  .contact textarea {
    width: 100%;
    box-sizing: border-box;
    background: none;
    resize: none;
    border: 0;
    border-bottom: 0.125rem solid var(--primary-dark-grey);
    border-radius: 0;
    color: var(--primary-green);
    outline: 0 !important;
    box-shadow: none !important;
    margin-bottom: 1rem;
    padding-left: 0 !important;
  }
  
  .contact .container {
    padding: 4rem 0;
  }
  
  .contact input:focus,
  .contact textarea:focus {
    background: transparent;
    color: var(--primary-green);
    border-bottom: 0.125rem solid var(--primary-dark-grey);
  }
  
  .contact textarea {
    height: 12.2rem !important;
  }
  
  .contact-btn {
    margin: 1rem 0 0 0;
    width: 100%;
    background: none;
  }
  
  .line {
    position: relative;
    top: -0.8rem;
    width: 0;
    height: 0.125rem;
    background: var(--primary-green);
    display: inline-block;
  }
  
  input:focus + .line,
  textarea:focus + .line {
    width: 100%;
    transition: width 0.5s ease-in-out;
  }
  
  input.form-control {
    margin: -0.3rem 0;
  }
  
  textarea.form-control {
    margin: -0.3rem 0;
  }
  
  .error-message {
    color: var(--primary-white);
    background: var(--primary-hover-blue);
    text-align: left;
  }
  
  .success-message {
    font-weight: 900;
    color: var(--primary-contact-green);
  }
  
  /* --- media ---  */
  @media (max-width: 768px) {
    /* .contact {
      overflow-x: hidden;
    } */
  
    .contact .container {
      padding: 0 1rem;
    }
  
    .contact p {
      padding: 0 0.4rem;
    }
  }
:root {
    --primary-dark-grey: #666;
    --primary-green: #6bd425;
    --primary-black: #000;
  }
  
  /* ***************** footer **************************** */
  .footer {
    background: var(--primary-black);
    color: var(--primary-dark-grey);
    padding: 3em;
  }
  
  .footer a {
    text-decoration: none;
    color: var(--primary-dark-grey);
  }
  
  .footer a:hover {
    text-decoration: none;
    color: var(--primary-green);
    cursor: pointer;
  }
  
  .footer p {
    margin-bottom: 0;
  }
