:root {
    --primary-white: #e2e2e2;
    --primary-light-grey: #f4f4f4;
    --primary-dark-grey: #666;
    --primary-green: #6bd425;
    --primary-dark: rgb(52, 58, 64);
    --primary-hover-blue: #0a2463;
    --primary-black: #000;
    --primary-contact-green: green;
  }
  
  /* ***************** contact **************************** */
  .contact {
    background: var(--primary-dark);
    padding: 3rem 0;
    max-width: 100%;
  }
  
  .contact h1 {
    color: var(--primary-green);
    padding: 1.3rem 0;
    text-transform: uppercase;
  }
  
  .contact p {
    color: var(--primary-light-grey);
    font-size: 1.2rem;
  }
  
  /* --- inputs ---  */
  .contact input,
  .contact textarea {
    width: 100%;
    box-sizing: border-box;
    background: none;
    resize: none;
    border: 0;
    border-bottom: 0.125rem solid var(--primary-dark-grey);
    border-radius: 0;
    color: var(--primary-green);
    outline: 0 !important;
    box-shadow: none !important;
    margin-bottom: 1rem;
    padding-left: 0 !important;
  }
  
  .contact .container {
    padding: 4rem 0;
  }
  
  .contact input:focus,
  .contact textarea:focus {
    background: transparent;
    color: var(--primary-green);
    border-bottom: 0.125rem solid var(--primary-dark-grey);
  }
  
  .contact textarea {
    height: 12.2rem !important;
  }
  
  .contact-btn {
    margin: 1rem 0 0 0;
    width: 100%;
    background: none;
  }
  
  .line {
    position: relative;
    top: -0.8rem;
    width: 0;
    height: 0.125rem;
    background: var(--primary-green);
    display: inline-block;
  }
  
  input:focus + .line,
  textarea:focus + .line {
    width: 100%;
    transition: width 0.5s ease-in-out;
  }
  
  input.form-control {
    margin: -0.3rem 0;
  }
  
  textarea.form-control {
    margin: -0.3rem 0;
  }
  
  .error-message {
    color: var(--primary-white);
    background: var(--primary-hover-blue);
    text-align: left;
  }
  
  .success-message {
    font-weight: 900;
    color: var(--primary-contact-green);
  }
  
  /* --- media ---  */
  @media (max-width: 768px) {
    /* .contact {
      overflow-x: hidden;
    } */
  
    .contact .container {
      padding: 0 1rem;
    }
  
    .contact p {
      padding: 0 0.4rem;
    }
  }