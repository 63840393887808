:root {
  --primary-white: #e2e2e2;
  --primary-light-grey: #f4f4f4;
  --primary-green: #6bd425;
  --primary-dark: rgb(52, 58, 64);
}

/* ***************** Services **************************** */
.skills {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.skills h1 {
  color: var(--primary-green);
  text-transform: uppercase;
}

.skills .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-green);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.skills .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.skills .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-green);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
  text-align: center;
}

.skills .box:hover {
  background: var(--primary-green);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.skills .box:hover .icon {
  color: var(--primary-dark);
}

.skills .box:hover .circle {
  background: var(--primary-white);
}

.skills .box:hover p {
  color: var(--primary-white);
}