:root {
    --primary-light-grey: #f4f4f4;
    --primary-green: #6bd425;
  }
  .p72 {
    max-width: 40%;
    }
    .Nucamp {
      max-width: 40%;
    }
    .tarjeta {
      max-width:  30%;
    }
  .contacts {

    max-width: 40%;
  }
  .portfolio {
    max-width: 30%;
  }
  /* ***************** Portfolio **************************** */
  .portfolio-wrapper {
    background: var(--primary-light-grey);
    padding: 5rem ;
  }
  
  .portfolio-wrapper h1 {
    color: var(--primary-green);
  }
  
  .portfolio-image {
    width: 18rem;
    border: 1px solid var(--primary-green);
    padding: 0 0.5rem;
  }
  
  .portfolio-image-box {
    position: relative;
    margin: 1rem;
    max-width: 40%;
  }
  
  .overflow {
    width: 18rem;
    height: 11rem;
      }