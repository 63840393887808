@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


:root {
  --primary-green: #6bd425;
  --primary-blue: #247ba0;
  --primary-black: #000;
  --primary-dark: rgba(255, 255, 255, 0);
}


body {
  font-family: 'Roboto' sans-serif !important;
}

/* ***************** Media **************************** */
@media (max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }

  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }

  .timeline-block-right {
    float: none;
    width: 100%;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
}

/* ***************** Overflow box **************************** */
.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid var(--primary-blue);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: var(--primary-green);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

/* ***************** Overflow box **************************** */
.portfolio-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: var(--primary-green);
}

.hyper-link:hover {
  color: var(--primary-blue);
}

/* ***************** Media **************************** */
@media (max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
}

@media (max-height: 570px) {
  .popupbox-wrapper {
    height: 100%;
  }

  .portfolio-image-popupbox {
    width: 50%;
  }
}

@media (max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }

  .portfolio-image-popupbox {
    width: 30%;
  }
}