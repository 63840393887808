@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

  :root {
    --primary-green: #6bd425;
    --primary-blue: #247ba0;
    --primary-black: #000;
    --primary-dark: rgba(255, 255, 255, 0);
  }
  nav {
    z-index: 2;
    position: fixed;  
    top: 0px;
}

  .logo {
    width: 13.50rem;
    margin-right: 7.50rem;
    margin-left: -19.50rem;

  
  }
  
  /* ------------ Nav Links -----------*/
  nav a.nav-link {
    letter-spacing:  0.075rem;
    cursor: pointer;
       
  }
  
  nav ul li {
    text-transform: uppercase;
    
  }
  
  .navbar-nav > li > a {
    color: var(--primary-green) !important;
    margin-right: 0.75rem;
    margin-left:  0.75rem;
    border-top: 0.1875 solid var(--primary-dark);
    font-weight: bolder;
    font-family: 'Bebas Neue'  cursive !important;
    font-size:  160%;

  }
  
  

  
  
  
  .navbar-brand {
    max-width: 17.25rem;
    padding: 0 !important;
    height: 6rem !important;
  }
  
  @media (max-width: 768px) {
    .navbar-brand {
      width: 7rem;
    }
  }
  
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    background: var(--primary-green);
    border-bottom: 0.3125rem solid var(--primary-dark);  }
  
    .navbar-nav > li > a:hover {
      border-top: 0.1875rem solid var(--primary-green);
    }