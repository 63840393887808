:root {
    --primary-grey: #605f5e;
    --primary-dark-grey: #666;
    --primary-green: #6bd425;
    --primary-dark: rgb(52, 58, 64);
    --primary-blue: #247ba0;
  }
  
  /* ***************** Experience **************************** */
  .experience {
    padding: 0.7rem;
  }
  
  .experience-wrapper {
    position: relative;
    padding: 3.125rem 0;
    overflow: hidden;
  }
  
  .experience h1 {
    text-transform: uppercase;
    color: var(--primary-green);
  }
  
  .experience-wrapper::before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    margin-left: -0.0625rem;
    width: 0.125rem;
    height: 100%;
    background: var(--primary-grey);
    z-index: 1;
  }
  
  .timeline-block {
    width: calc(50% + 0.5rem);
    display: flex;
    justify-content: space-between;
    clear: both;
  }
  
  .timeline-block-right {
    float: right;
  }
  
  .timeline-block-left {
    float: left;
    direction: rtl;
  }
  
  .marker {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 0.3125rem solid var(--primary-dark);
    background: var(--primary-green);
    margin-top: 0.625rem;
    z-index: 3;
  }
  
  .timeline-content {
    width: 95%;
    padding: 0 0.9375rem;
    color: var(--primary-dark-grey);
    direction: ltr;
  }
  
  .timeline-content h3 {
    margin-top: 0.3125rem;
    color: var(--primary-blue);
    font-weight: 900;
  }